import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics"; 
// import { getFirestore } from 'firebase/firestore'; 
import { 
        connectDatabaseEmulator, 
        getDatabase, 
        onValue, 
        ref, 
        query,
        orderByChild,
        limitToFirst,
        limitToLast,
        set, 
        push, 
        update, 
        remove, 
        serverTimestamp } from 'firebase/database';
import { getAuth, 
        signInWithEmailAndPassword, 
        createUserWithEmailAndPassword, 
        signOut, 
        updatePassword, 
        sendPasswordResetEmail, 
        EmailAuthProvider,
        GoogleAuthProvider, 
        TwitterAuthProvider,
        FacebookAuthProvider, 
        signInWithPopup, 
        signInAnonymously,
        connectAuthEmulator,
        fetchSignInMethodsForEmail,
        linkWithCredential,
        linkWithPopup,
        unlink,
        sendEmailVerification,
} from 'firebase/auth'; 

// uses either the production or development environment variables
// that are set in .env.production or .env.development
const config = {
    // import.meta.env. is used for vite
    // replace with process. for create-react-app version
    apiKey: "AIzaSyB5wgHoPtBQLhYKJ8ZKlEN2bT65VWG5oig",
    authDomain: "spare-mic.firebaseapp.com",
    projectId: "spare-mic",
    storageBucket: "spare-mic.appspot.com",
    messagingSenderId: "598159670234",
    appId: "1:598159670234:web:6e69ee980ac88a5894dee3",
    measurementId: "G-Q7TBBBQHPW"
}

class Firebase {
    // initialize firebase with the configuration 
    constructor() {
        // Initialize Firebase
        const app = initializeApp(config);

        // Initialize Firebase Authentication and get a reference to the service
        this.auth = getAuth(app);
        this.auth.useDeviceLanguage(); 

        // Initialize Realtime Database and get a reference to the service
        this.db = getDatabase(app)

        // if (import.meta.env.MODE === 'emulator') {
        //     connectAuthEmulator(this.auth, "http://localhost:9099");
        //     // connectDatabaseEmulator(this.db, "http://localhost:9000/?ns=learn-firestore-dev");
        // }  

        // Initialize Firebase Analytics and get a reference to the service
        this.analytics = getAnalytics(app)

        // Initialize Cloud Firestore and get a reference to the service
        // const fs = getFirestore(app)

        this.emailAuthProvider = new EmailAuthProvider();

        this.googleProvider = new GoogleAuthProvider();
         // if you just want the contacts, alternatives available https://developers.google.com/identity/protocols/oauth2/scopes
        //  this.googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');

        this.twitterProvider = new TwitterAuthProvider(); 

        this.facebookProvider = new FacebookAuthProvider(); 
    }

    // *** Authentication API *** 

    // create a custom function that takes an email and password 
    // and creates an account on firebase
    doCreateUserWithEmailAndPassword = (email, password) =>
        createUserWithEmailAndPassword(this.auth, email, password)
    
    // creates a custom function for login/sign-in functionality, 
    // which takes email and password parameters
    doSignInWithEmailAndPassword = (email, password) => 
        signInWithEmailAndPassword(this.auth, email, password)

    doSendEmailVerification = () => {
        return sendEmailVerification(this.auth.currentUser, {
            url: config.redirectURL,
        })
    }
    
    // creates a custom function for logout/sign-out functionality
    doSignOut = () => 
        signOut(this.auth)

    // creates a custom function to send a password reset email
    doPasswordReset = email => 
        sendPasswordResetEmail(this.auth, email)

    // creates a custom function to update the password of a user
    doPasswordUpdate = password => 
        updatePassword(this.auth.currentUser, password)

    // *** Social SignIn *** //

    // given a provider id, return the provider
    getProviderWithProviderID = providerID => {
        // get all providers
        const providers = this.getProviders();
        // find the provider with the given id
        const provider = providers.find(provider => provider.id === providerID);
    }

    // this function returns an array of objects that have all prvoiders
    // each object has the id and provider
    getProviders = () => {
        return [
            {
                id: 'password',
                provider: this.emailAuthProvider
            },
            {
                id: 'google.com',
                provider: this.googleProvider
            },
            {
                id: 'twitter.com',
                provider: this.twitterProvider
            },
            {
                id: 'facebook.com',
                provider: this.facebookProvider
            }
        ]
    }
    
    // given a firebase provider, sign in with it
    doSignInWithProvider = provider => signInWithPopup(this.auth, provider)

    doSignInWithGoogle = () => signInWithPopup(this.auth, this.googleProvider)

    doSignInWithTwitter = () => signInWithPopup(this.auth, this.twitterProvider)

    doSignInWithFacebook = () => signInWithPopup(this.auth, this.facebookProvider)

    doSignInAnonymously = () => signInAnonymously(this.auth)

    getSignInMethods = email => fetchSignInMethodsForEmail(this.auth, email)

    // given a firebase prvoider, unlink it from the current user
    doUnlinkProvider = providerID => unlink(this.auth.currentUser, providerID);

    // given a firebase provider, link it to the current user
    doLinkProvider = provider => { 
        // const provider = this.getProviderWithProviderID(providerID);
        return linkWithPopup(this.auth.currentUser, provider)
    }

    getCurrentUser = () => {
        return this.auth.currentUser
    }

    getCredentialsFromEmail = (email, password) => {
        return EmailAuthProvider.credential(email, password);
    }

    getCredentialsFromError = (providerID, error) => {
        // get the provider with the provider id
        const provider = this.getProviderWithProviderID(providerID);
        // get the credentials from the provider and the error
        // here we call the constructor because the function 'credentialFromError' is static
        return provider.constructor.credentialFromError(error);
    } 

    linkWithCred = cred => linkWithCredential(this.auth.currentUser, cred)

    // *** User API firebase realtime database *** 

    getServerTime = () => {
        return serverTimestamp();
    }
    
    // create a user in firebase database
    doDBCreateUser = (uid, username, email, admin, paid, paidType) => {
        const reference = ref(this.db, 'users/' + uid)
        set(reference, {
            username: username,
            email: email, 
            admin,
            paid,
            paidType,
        });
    }

    // retrieve a reference to a user from the users 
    user = uid => ref(this.db, 'users/' + uid);

    // retrieve a reference to all users
    users = () => ref(this.db, 'users');

    // get user uses the user uid provided and a call backfunction to return the found user
    getUser = (uid, cbf) => {
        const reference = ref(this.db, 'users/' + uid);
        // here the listener for the reference is returned
        return onValue(reference, (snapshot) => {{
            const userObject = { 
                ...snapshot.val(),
                uid: snapshot.ref.key,
            };

            // the callback function that was provided is called
            cbf(userObject); 
        }})
    }

    // retrieve all users from the users
    getUsers = () => {
        // return a new Promise 
        return new Promise((resolve, reject) => {
            const reference = ref(this.db, 'users');

            // get a snapshot of the reference once
            onValue(reference, (snapshot) => {
                const usersObject = snapshot.val();

                const usersList = Object.keys(usersObject).map(key => ({
                    ...usersObject[key],
                    uid: key,
                }));
                resolve(usersList);
            }, 
            // cancelCallback notified if your event subscription is ever canceled because your client does not have permission to read this data
            (error) => { 
                reject(error)
            }, 
            // An object that can be used to configure onlyOnce, which then removes the listener after its first invocation.
            {
                // by making it only once, it needs to be loaded once and isn't expected to change frequently 
                onlyOnce: true
            })
        })
    }

    setUser = (ref, username, email, roles) => {
        return set(ref, {
            username, 
            email, 
            roles,
        })
    }

    // *** Merge Auth and DB User API *** //

    // the next() and fallback() callback functions are used to  implement the specific implementation details 
    // of every higher-order component (local state for authentication, redirect for authorization)
    onAuthUserListener = (next, fallback) => {
        // it’s best to merge the authentication user and database user in this component before checking 
        // for its privileges (roles, permissions).
        return this.auth.onAuthStateChanged( authUser => {
            if(authUser) {
                // retrieves a reference to the user
                const userRef = this.user(authUser.uid)
                onValue(userRef, snapshot => {
                    const dbUser = snapshot.val(); // get the database user

                    // default empty roles
                    if (dbUser && !dbUser.roles) {
                        dbUser.roles = {}
                    }

                    // merge auth and database user
                    authUser = {
                        ...authUser,
                        ...dbUser,
                    }

                    // set the auth user
                    next(authUser)
                }, {
                    onlyOnce: true
                }) 
            } else {
                fallback();
            }
        })
    }

    // *** Message API *** //
    
    message = uid => ref(this.db, `messages/${uid}`);

    messages = () => ref(this.db, 'messages');

    listenToMessages = (callBackFunction, limit) => {
        const reference = query(this.messages(), orderByChild('editedAt'));
        const limitRef = query(reference, limitToFirst(limit));
        return onValue(limitRef, (snapshot) => {
            const messagesObject = snapshot.val();

            if (messagesObject) {
                const messagesList = Object.keys(messagesObject).map(key => ({
                    ...messagesObject[key],
                    uid: key,
                }));
    
                if (messagesList) {
                    callBackFunction(messagesList);
                }
            } else {
                callBackFunction(null)
            }
        })
    }

    doCreateMessage = (uid, message) => {
        const reference = ref(this.db, `messages/${uid}`);
        set(reference, message);
    }

    doAddMessage = (message) => {
        push(this.messages(), message);
    }

    doUpdateMessage = (uid, message) => {
        const reference = ref(this.db, `messages/${uid}`);
        update(reference, message);
    }

    doDeleteMessage = (uid) => {   
        const reference = ref(this.db, `messages/${uid}`);
        remove(reference);
    }




}

export default Firebase; 