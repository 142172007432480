// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB5wgHoPtBQLhYKJ8ZKlEN2bT65VWG5oig",
  authDomain: "spare-mic.firebaseapp.com",
  projectId: "spare-mic",
  storageBucket: "spare-mic.appspot.com",
  messagingSenderId: "598159670234",
  appId: "1:598159670234:web:6e69ee980ac88a5894dee3",
  measurementId: "G-Q7TBBBQHPW"
};

export default firebaseConfig;
