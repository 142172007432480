import './App.scss';

import { Switch, Route } from 'react-router-dom';

import SpareMic from './pages/sparemic/sparemic.component';
import PricingPage from './pages/pricing/pricing.component';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path='/' exact component={SpareMic}/>
        <Route path='/sparemic/' exact component={SpareMic}/>
        <Route path='/pricing' exact component={PricingPage}/>
      </Switch>
    </div>
  );
}

export default App;
