import React from 'react'; 

// we use the context to create a single instance (singleton)
// of the firebase class. It creates two components, the provider;
// used to provide the Firebase instance and the consumer;
// which is used to retreive the Firebase instance
const FirebaseContext = React.createContext(null); 

// components can use this method to become higher order components
// that have the firebase instance already in their prop
export const withFirebase = Component => props => (
    <FirebaseContext.Consumer>
        {firebase => <Component {...props} firebase={firebase} />}
    </FirebaseContext.Consumer>
)

export default FirebaseContext;